<template>
	<CRow>
		<CCol col="12">
			<CCard>
				<CCardHeader>
					Centro Notifiche
				</CCardHeader>
				<CCardBody>
					<CForm>
						<CRow>
							<CCol
								xs="12"
								sm="6"
								md="6"
								lg="6"
								xl="6"
								xxl="6"
								class="mb-sm-2 mb-0"
							>
								<CInput
									label="Titolo"
									:value="validatorObj.title.$model"
									@update:value="setTitle"
									:isValid="requiredValidTouched(validatorObj.title)"
								>
								</CInput>
							</CCol>
							<CCol
								xs="12"
								sm="6"
								md="6"
								lg="6"
								xl="6"
								xxl="6"
								class="mb-sm-2 mb-0"
							>
								<CInput
									label="URL"
									:value="validatorObj.url.$model"
									@update:value="setUrl"
									:isValid="requiredValidTouched(validatorObj.url)"
								>
								</CInput>
							</CCol>
							<CCol
								lg="12"
								class="mb-sm-2 mb-0"
							>
								<CTextarea
									label="Messaggio"
									:value="validatorObj.body.$model"
									@update:value="setBody"
									:isValid="requiredValidTouched(validatorObj.body)"
								>
								</CTextarea>
							</CCol>

						</CRow>
					</CForm>
				</CCardBody>
				<CCardFooter>
					<div class="footer-actions">
						<CButton
							color="sdAzure"
							@click="sendNotification"
							:disabled="validatorObj.body.$invalid || validatorObj.title.$invalid || validatorObj.url.$invalid"
						>Invia ora</CButton>
					</div>
				</CCardFooter>
			</CCard>
		</CCol>
	</CRow>
</template>

<script>

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { requiredValidTouched, deleteModalOptions, pushToast, onlyHttpLinkValidator } from "../../utilities/utilities";
import { mapActions } from "vuex";

export default {
	name: 'Notifiche',
	validations() {
		return {
			body: { required },
			title: { required },
			url: { onlyHttpLinkValidator }
		}
	},
	setup() {
		return { validatorObj: useVuelidate() };
	},
	data() {
		return {
			body: "",
			title: "",
			url: "",
		}
	},
	methods: {
		...mapActions(["sendNotification"]),
		setBody(value) {
			this.validatorObj.body.$model = value;
		},
		setTitle(value) {
			this.validatorObj.title.$model = value;
		},
		setUrl(value) {
			this.validatorObj.url.$model = value;
		},
		sendNotification() {
			this.$store.dispatch('sendNotification', {
				title: this.title,
				body: this.body,
				url: this.url,
			}).then(x => {
				this.deleteModalOptions();
				this.setBody(undefined);
				this.validatorObj.body.$reset();
				this.setTitle(undefined);
				this.validatorObj.title.$reset();
				this.setUrl(undefined);
				this.validatorObj.url.$reset();
				this.pushToast({ title: 'Inviato', body: 'Notifica inviata', color: 'success' });
			});
		},
		requiredValidTouched,
		deleteModalOptions,
		pushToast,
	}
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>